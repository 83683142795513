import { Link } from "react-router-dom";
import { useDocumentTitle } from "../hooks";
import Routes from "../routes";

function LegalNotices() {
  useDocumentTitle("Mention légales | Appenvie");

  return (
    <div className="my-0 mx-auto" style={{ maxWidth: 960 }}>
      <h1 className="text-center">Mentions Légales</h1>

      <p className="mt-4 text-center">
        Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux utilisateurs du Site l'identité des différents intervenants
        dans le cadre de sa réalisation et de son suivi.
      </p>
      <p className="mt-4">
        Les présentes Mentions légales sont également valables sur l'ensemble des domaines suivants sur lesquels l'utilisateur a également accès avec les mêmes identifiants de connexion que sur
        appenvie.fr :
      </p>
      <ul>
        <li>atelier.appenvie.fr</li>
        <li>banque.appenvie.fr</li>
      </ul>

      <h2 className="mt-3">Édition du site</h2>
      <div>
        <p className="mb-2">Responsable du site : L'association M@ths'n CO</p>
        <p className="mb-3">Siège social : 60, place du poilu 74400 Chamonix</p>
        <p className="mb-2">
          Pour toutes demandes d'informations ou réclamations veuillez contacter le responsable de publication du site appenvie.fr via le <Link to={Routes.CONTACT}>formulaire de contact</Link>
        </p>
        <p className="mb-2">Responsable publication : Christophe GILGER</p>
        <p className="mb-2">Responsable technique : Rémi GILGER</p>
      </div>

      <h2 className="mt-3">Hébergement</h2>
      <div>
        <p>O2Switch</p>
        <p>SAS au capital de 100 000€</p>
        <p>RCS Clermont Ferrand</p>
        <p>Code APE: 63.11Z</p>
        <p>N° TVA: FR35510909807</p>
        <p>Siège social: CHE DES PARDIAUX, 63000 CLERMONT FERRAND</p>
      </div>

      <h2 className="mt-3">Collecte de données et loi informatique et libertés</h2>
      <p>
        Voir notre <Link to={Routes.PRIVACY}>politique de confidentialité</Link>
      </p>

      <h2 className="mt-3">Loi applicable et juridiction</h2>
      <p>
        Les présentes Mentions Légales sont régies par la loi française. En cas de différend et à défaut d'accord amiable, le litige sera porté devant les tribunaux français conformément aux règles de
        compétence en vigueur.
      </p>
      <p>
        Voir nos <Link to={Routes.CGU}>Conditions Générales d'Utilisation</Link>
      </p>

      <h2 className="mt-3">Code source</h2>
      <p>
        Le code source du site appenvie.fr est disponible sur la forge des communs numériques :{" "}
        <a href="https://forge.apps.education.fr/remi.gilger-ext/les-apps-maths-en-vie">https://forge.apps.education.fr/remi.gilger-ext/les-apps-maths-en-vie</a>.
      </p>
      <p>
        Le code source est sous license <a href="https://www.gnu.org/licenses/gpl-3.0-standalone.html">GNU General Public License v3.0 or later</a>.
      </p>
    </div>
  );
}

export default LegalNotices;
