import { useDocumentTitle } from "../hooks";

function Credits() {
  useDocumentTitle("Crédits | Appenvie");

  return (
    <div className="my-0 mx-auto" style={{ maxWidth: 960 }}>
      <h1 className="text-center">Crédits</h1>

      <h2 className="mt-5">Crédits</h2>
      <p className="mt-3">
        Conception : Christophe GILGER{" "}
        <a href="https://www.linkedin.com/in/christophegilger/" className="link-primary link-underline-opacity-0 link-underline-opacity-100-hover">
          Linkedin
        </a>
      </p>

      <p className="mt-2">
        Conception et programmation : Rémi GILGER{" "}
        <a href="https://www.linkedin.com/in/r%C3%A9mi-gilger-b7984b232/" className="link-primary link-underline-opacity-0 link-underline-opacity-100-hover">
          Linkedin
        </a>
      </p>

      <h2 className="mt-4">Remerciements</h2>
      <ul className="mt-3">
        <li>À l'association M@ths'n Co qui soutient et finance ce projet</li>
        <li>À la DRANE de Grenoble pour son accompagnement</li>
        <li>À tous les enseignants pour leurs retours d'usages avec leurs élèves</li>
        <li>À tous les enseignants ayant contribué à la banque collaborative sur laquelle s'appuie l'application</li>
      </ul>

      <h2 className="mt-4">Ils parlent de nous</h2>
      <ul className="mt-3">
        <li>
          <a
            className="link-primary link-underline-opacity-0 link-underline-opacity-100-hover"
            href="https://www.ludomag.com/2024/09/24/mths-en-vie-lance-une-application-pour-sentrainer-a-la-resolution-des-problemes/"
          >
            Ludomag
          </a>
        </li>
        <li>
          <a className="link-primary link-underline-opacity-0 link-underline-opacity-100-hover" href="https://cafepedagogique.net/2024/10/16/dans-latelier-des-problemes-de-maths/">
            Café Pédagogique
          </a>
        </li>
        <li>
          <a
            className="link-primary link-underline-opacity-0 link-underline-opacity-100-hover"
            href="https://www.educavox.fr/innovation/didactique/une-application-innovante-pour-s-entrainer-a-resoudre-des-problemes-1"
          >
            Educavox
          </a>
        </li>
        <li>
          <a className="link-primary link-underline-opacity-0 link-underline-opacity-100-hover" href="https://primabord.eduscol.education.fr/l-atelier-des-problemes">
            Primàbord
          </a>
        </li>
        <li>
          <a className="link-primary link-underline-opacity-0 link-underline-opacity-100-hover" href="https://outilstice.com/2024/10/atelier-des-problemes-exercies-mathematiques/">
            Outilstice
          </a>
        </li>
      </ul>

      <h2 className="mt-4">Technologies utilisées</h2>
      <p className="mt-3">
        Icones :{" "}
        <a href="https://fontawesome.com/" className="link-primary link-underline-opacity-0 link-underline-opacity-100-hover">
          FontAwesome
        </a>
      </p>
      <p className="mt-2">
        Frontend :{" "}
        <a href="https://react.dev/" className="link-primary link-underline-opacity-0 link-underline-opacity-100-hover">
          React
        </a>{" "}
        -{" "}
        <a href="https://getbootstrap.com/" className="link-primary link-underline-opacity-0 link-underline-opacity-100-hover">
          Bootstrap
        </a>
      </p>
      <p className="mt-2">
        Backend :{" "}
        <a href="https://symfony.com/" className="link-primary link-underline-opacity-0 link-underline-opacity-100-hover">
          Symfony
        </a>
      </p>
      <p className="mt-2">
        IA :{" "}
        <a href="https://platform.openai.com/docs/models/gpt-4o-mini" className="link-primary link-underline-opacity-0 link-underline-opacity-100-hover">
          Chat GPT (gpt-4o-mini)
        </a>
      </p>
    </div>
  );
}

export default Credits;
