import { faBank, faBraille, faCalendarDays, faCamera, faChartSimple, faInfoCircle, faTowerObservation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Routes from "../routes";
import { useModals } from "../contexts/modals";
import { ModalTypeEnum } from "../utils";

function Home() {
  const { openModal } = useModals();
  return (
    <div className="m-auto position-relative" style={{ maxWidth: 960 }}>
      <div className="text-center">
        <h1 className="fw-semibold d-flex flex-wrap justify-content-center gap-2">
          <span>Les applications</span> <span>M@ths en-vie</span>
        </h1>
        <div className="container py-4" id="hanging-icons">
          <div className="row gx-4 gy-5 row-cols-1 row-cols-lg-3">
            <div className="col d-flex align-items-start">
              <div className="fs-3 me-3">
                <FontAwesomeIcon icon={faBank} />
              </div>
              <div className="text-start">
                <h2 className="fs-2 text-body-emphasis">La Banque</h2>
                <p className="mb-3">Une banque collaborative qui s'appuie sur une typologie avec des outils de recherche, d'édition et de vidéoprojection</p>
                <a className="btn btn-primary" href={process.env.REACT_APP_BANQUE_URL}>
                  Accéder
                </a>
              </div>
            </div>
            <div className="col d-flex align-items-start">
              <div className="fs-3 me-3">
                <FontAwesomeIcon icon={faTowerObservation} />
              </div>
              <div className="text-start">
                <h2 className="fs-2 text-body-emphasis">
                  <button
                    className="btn p-0 text-secondary"
                    onClick={() =>
                      openModal(ModalTypeEnum.Message, {
                        title: "En cours de développement...",
                        content:
                          "<span class=\"fw-bold\">L'atelier</span> est actuellement <span class=\"fw-bold\">en cours de développement</span> : il est donc possible que des erreurs apparaissent, n'hésitez pas à nous les signaler. Vous pouvez néanmoins utiliser l'application et nous serions ravis d'avoir vos retours.",
                      })
                    }
                  >
                    <FontAwesomeIcon className="fs-3 align-self-center" icon={faInfoCircle} />
                  </button>{" "}
                  L'atelier
                </h2>
                <p className="mb-3">Un logiciel pour s'entraîner à résoudre des problèmes avec des aides tutorielles et un suivi individuel des élèves</p>
                <div className="d-flex">
                  <a className="btn btn-primary" href={process.env.REACT_APP_ATELIER_URL}>
                    Accéder
                  </a>
                  <p className="align-self-center ms-3 fw-bold text-secondary">NEW !</p>
                </div>
              </div>
            </div>
            <div className="col d-flex align-items-start">
              <div className="fs-3 me-3">
                <FontAwesomeIcon icon={faCalendarDays} />
              </div>
              <div className="text-start">
                <h2 className="fs-2 text-body-emphasis">Le rituel</h2>
                <p className="mb-3">Chaque jour d'école, pour chaque niveau, un nouveau problème en fonction d'une programmation et d'une progression</p>
                <Link to={Routes.RITUEL} className="btn btn-primary">
                  Accéder
                </Link>
              </div>
            </div>
            <div className="col d-flex align-items-start">
              <div className="fs-3 me-3">
                <FontAwesomeIcon icon={faCamera} />
              </div>
              <div className="text-start">
                <h2 className="fs-2 text-body-emphasis">Les outils</h2>
                <p className="mb-3">3 logiciels pour catégoriser des photos, les annoter et générer des photo-problèmes pour la classe</p>
                <a className="btn btn-primary" href="https://www.mathsenvie.fr/le-classeur-dactivites">
                  Accéder
                </a>
              </div>
            </div>
            <div className="col d-flex align-items-start">
              <div className="fs-3 me-3">
                <FontAwesomeIcon icon={faChartSimple} rotation={90} />
              </div>
              <div className="text-start">
                <h2 className="fs-2 text-body-emphasis">Modélisation en barres</h2>
                <p className="mb-3">Une application pour modéliser tous les types de problèmes pour un usage collectif en vidéoprojection ou en individuel sur ordinateur</p>
                <a className="btn btn-primary" href="https://www.mathsenvie.fr/la-methode">
                  Accéder
                </a>
              </div>
            </div>
            <div className="col d-flex align-items-start">
              <div className="fs-3 me-3">
                <FontAwesomeIcon icon={faBraille} />
              </div>
              <div className="text-start">
                <h2 className="fs-2 text-body-emphasis">How Many</h2>
                <p className="mb-3">
                  Une application partenaire liant calcul et images mentales : construction du nombre, sens des opérations, raisonnement logique, automatisation des faits numériques, modélisation,
                  pré-algébrisation
                </p>
                <a className="btn btn-primary" href="https://howmanyjeu.fr">
                  Accéder
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
