import { Link } from "react-router-dom";
import { useDocumentTitle } from "../hooks";
import Routes from "../routes";

function Cgu() {
  useDocumentTitle("Conditions Générales d'Utilisation | Appenvie");

  return (
    <div className="my-0 mx-auto" style={{ maxWidth: 960 }}>
      <h1 className="text-center">Conditions Générales d'Utilisation</h1>
      <p className="mt-3 fst-italic">Version des CGU du 01/09/2024</p>

      {/* Object ============================================================== */}

      <h2 className="mt-3">1. Object</h2>
      <p className="mt-3">
        M@ths'n Co. propose un écosystème d'applications (ci-après&nbsp;: les «&nbsp;Apps&nbsp;») à destination des enseignants, des élèves et des parents afin de travailler la résolution de
        problèmes. Elles s'articulent autour d'une banque collaborative permettant à tout un chacun de soumettre ses problèmes, photo et photo-problèmes qui seront utilisés dans les différents Apps.
      </p>

      <p className="mt-3">Les Apps se composent de 3 outils interconnectés&nbsp;:</p>
      <ul>
        <li>La banque collaborative de problèmes, photos et photo-problèmes</li>
        <li>Le rituel de problèmes</li>
        <li>L'atelier des problèmes</li>
      </ul>

      <p className="mt-3">
        M@ths'n Co. fournit, par l'intermédiaire de son site Internet (ci-après&nbsp;: le «&nbsp;Site&nbsp;»), des Services à destination des enseignants et des parents (ci-après&nbsp;: les
        «&nbsp;Utilisateurs&nbsp;») dédiés à l'enseignement et à l'apprentissage des mathématiques et notamment de la résolution de problèmes.
      </p>

      <p className="mt-3">Le Site comprend les domaines suivants&nbsp;:</p>
      <ul>
        <li>appenvie.fr</li>
        <li>banque.appenvie.fr</li>
        <li>atelier.appenvie.fr</li>
        <li>rituel.appenvie.fr</li>
      </ul>

      <p className="mt-3">
        Les présentes conditions générales ont pour objet de définir les modalités et conditions d'utilisation des Services proposés sur le Site, ainsi que de définir les droits et obligations des
        Utilisateurs dans ce cadre. Elles sont accessibles et imprimables à tout moment.
      </p>

      <p className="mt-3">Le Site et les Services sont exploités par M@ths'n Co. Plus de détails ainsi que les coordonnées de contact sont disponibles dans les Mentions légales.</p>

      {/* Acceptation des conditions générales ================================ */}

      <h2 className="mt-3">2. Acceptation des conditions générales</h2>

      <p className="mt-3">
        L'acceptation des présentes conditions générales par les Utilisateurs se fait lors de la création du compte. Cette acceptation ne peut être que pleine et entière. Toute adhésion sous réserve
        est considérée comme nulle et non avenue. Les Utilisateurs qui n'acceptent pas d'être liés par les présentes conditions générales ne doivent pas utiliser les Services.
      </p>

      <p className="mt-3">
        Les Services sont accessibles aux internautes étant inscrits au Site, toutefois une partie du Site est mise à disposition des internautes non identifiés à des fins de démonstration (se référer
        à la partie suivante pour plus détails). Si tel est votre cas, merci de lire attentivement ces conditions générales avant de naviguer sur le Site ou d'utiliser les Contenus, car elles
        constituent le cadre légal d'utilisation des Services. Dans ce contexte, le simple fait d'utiliser le Site vaut acceptation de ces conditions générales d'utilisation. Si vous n'êtes pas
        d'accord avec chacun des termes de ces conditions générales, n'utilisez pas le Site.
      </p>

      {/* Accès aux Services ================================================== */}

      <h2 className="mt-3">3. Accès aux Services</h2>

      <p className="mt-3">
        Il est possible de consulter une partie du Site et des contenus gratuitement, sans inscription. Ce mode de consultation vous permet de tester les fonctionnalités de la banque collaborative, de
        l'outil de rituel et de l'atelier des problèmes.
      </p>

      <p className="mt-3">
        La création d'un compte (ci-après&nbsp;: le «&nbsp;Compte&nbsp;») est gratuite au moyen d'un formulaire d'inscription disponible sur le Site. L'Utilisateur connecté suite à l'inscription est
        autorisé à utiliser les Services à titre privé ou en classe et dispose de plusieurs fonctionnalités de contribution, d'édition et de paramétrage.
      </p>

      <p className="mt-3">
        Afin d'accéder aux contenus de la banque collaborative, il est demandé à l'Utilisateur de contribuer à hauteur de 3 problèmes, 3 photos ou 3 photo-problèmes afin de pouvoir accéder
        respectivement à l'intégralité des contenus de la banque de problèmes, la banque de photos ou la banque de photo-problèmes. Les problèmes, photos et photo-problèmes sont soumis à validation
        d'un administrateur qui peut refuser une proposition de contribution.
      </p>

      <p className="mt-3">
        Plusieurs contenus et/ou fonctionnalités sont réservés aux Utilisateurs inscrits ou adhérents à l'association M@ths'n Co. Afin de pouvoir accéder à certains, il est nécessaire de saisir un
        code dans le profil Utilisateur. Ce code est valable pendant toute la durée de l'adhésion à l'association.
      </p>

      <p className="mt-3">
        Les Services sont accessibles à toute personne physique disposant de la pleine capacité juridique pour s'engager au titre des présentes conditions générales. La personne physique qui ne
        dispose pas de la pleine capacité juridique ne peut accéder aux Services qu'avec l'accord de son représentant légal ou, dans le cas d'élèves, sous la supervision d'une personne adulte au sein
        de leur établissement scolaire ou institution (enseignant, directeur).
      </p>

      <p className="mt-3">
        L'Utilisateur a la possibilité d'inviter des élèves (ci-après&nbsp;: les «&nbsp;Apprenants&nbsp;») à utiliser l'App «&nbsp;Atelier des problèmes&nbsp;». Dans ce cas, l'Utilisateur est
        responsable de l'accès aux Services des Apprenants. Le code personnel de l'Utilisateur lui permet de s'authentifier tous les terminaux des Apprenants afin de leur donner accès aux contenus.
      </p>

      {/* Usage strictement personnel ========================================= */}

      <h2 className="mt-3">4. Usage strictement personnel</h2>

      <p className="mt-3">
        L'Utilisateur est responsable du maintien de la confidentialité de son mot de passe et reconnaît expressément que toute utilisation des Services depuis son Compte sera réputée avoir été
        effectuée par lui-même.
      </p>

      <p className="mt-3">
        L'Utilisateur ne communique pas son mot de passe aux Apprenants, mais s'authentifie personnellement sur les postes afin que les Apprenants aient accès au service «&nbsp;L'atelier des
        problèmes&nbsp;».
      </p>

      <p className="mt-3">
        L'Utilisateur doit immédiatement contacter M@ths'n Co. aux coordonnées mentionnées à l'article 1 des présentes s'il remarque que son Compte a été utilisé à son insu. Il reconnaît à M@ths'n Co.
        le droit de prendre toutes mesures appropriées en pareil cas.
      </p>

      <p className="mt-3">
        Selon son profil, l'Utilisateur a accès à certaines ressources et certaines fonctionnalités. L'Utilisateur s'engage à ne partager les mots de passe qui lui a été fourni à titre personnel pour
        l'accès aux fonctionnalités spécifiques.
      </p>

      {/* Responsabilité des utilisateurs ===================================== */}

      <h2 className="mt-3">5. Responsabilité des utilisateurs</h2>

      <p className="mt-3">
        La publication de Contenus ainsi que de réactions aux Contenus (ci-après&nbsp;: les «&nbsp;Commentaires&nbsp;») suppose de respecter certaines règles fixées dans la loi. Nous en donnons ici un
        aperçu général.
      </p>

      <p className="mt-3">Il est notamment interdit d'insérer dans un Contenu ou Commentaire&nbsp;:</p>
      <ul>
        <li>des contenus injurieux ou diffamatoires&nbsp;;</li>
        <li>des contenus racistes, antisémites, homophobes, sexistes ou handiphobes&nbsp;;</li>
        <li>des contenus appelant à la haine, à la violence, à la discrimination ou à commettre des infractions&nbsp;;</li>
        <li>des contenus pornographiques ou incluant de la nudité&nbsp;;</li>
        <li>des contenus invitant à la consommation d'alcool ou de drogues&nbsp;;</li>
        <li>
          des contenus imitant une marque ou une œuvre protégée par le droit d'auteur. Il est possible, par exception, d'intégrer dans les Contenus des reproductions et/ou représentations de telles
          œuvres, sous réserve qu'elles respectent les conditions des exceptions prévues par l'article L. 122-5 du Code de la propriété intellectuelle.
        </li>
      </ul>

      <p className="mt-3">Un Contenu peut consister aussi bien dans du texte que dans du code informatique, une image ou une vidéo, cette liste n'étant pas limitative.</p>

      <p className="mt-3">M@ths'n Co. se réserve le droit de supprimer unilatéralement tout Contenu ou Commentaire ne respectant pas les règles ci-dessus ou à la demande des autorités compétentes.</p>

      <p className="mt-3">Les Contenus sont créés et diffusés sous la responsabilité, en premier lieu, de l'Utilisateur.</p>

      <p className="mt-3">
        L'Utilisateur qui invite des Apprenants à collaborer devient directeur des publications et endosse ainsi la responsabilité de ses publications ainsi que celles de ses Apprenants. Il s'agit
        nécessairement d'une personne majeure (enseignant, directeur, formateur, animateur, etc.).
      </p>

      <p className="mt-3">
        Les Apprenants peuvent toutefois être également responsables, du point de vue civil (*) et pénal (**), en cas de violation d'une règle légale ou réglementaire telle que celles évoquées
        ci-avant. En cas de doute ou pour toute question particulière, nous vous invitons à vous tourner vers la personne responsable au sein de votre établissement ou institution (enseignant,
        directeur, etc.).
      </p>

      <p className="mt-3">
        M@ths'n Co. vérifie et valide l'ensemble des problèmes, photos et photo-problèmes qui sont postés sur la plateforme. En cas d'erreur dans un énoncé ou dans son classement, l'Utilisateur a la
        possibilité de signaler un problème. M@ths'n Co. ne saurait être tenue responsable des problèmes, photos et photo-problèmes publiés et des commentaires postés par les Utilisateurs. Elle
        s'engage toutefois à retirer dans les meilleurs délais tout contenu non conforme.
      </p>

      <p className="mt-3">En résumé&nbsp;:</p>
      <ul>
        <li>
          si vous êtes un Utilisateur, vous êtes en premier lieu responsable des Contenus que vous publiez et, le cas échéant, des Contenus publiés par les Apprenants que vous avez invités&nbsp;;
        </li>
        <li>
          si vous êtes un Apprenant âgé de moins de 18&nbsp;ans (mineur), selon les cas, votre propre responsabilité peut être engagée en tant que simple auteur lorsque vous êtes considéré au regard
          du droit comme émancipé ou, à défaut, celle de vos représentants légaux (c'est-à-dire en principe vos parents).
        </li>
      </ul>

      {/* Protection des données personnelles ================================= */}

      <h2 className="mt-3">6. Protection des données personnelles</h2>

      <p className="mt-3">
        M@ths'n Co. accorde une grande importance au respect de la vie privée. Nous vous invitons à consulter notre <Link to={Routes.PRIVACY}>politique de protection des données personnelles</Link>.
      </p>

      <p className="mt-3">
        D'un commun accord, M@ths'n Co. et l'Utilisateur reconnaissent leur qualité de responsables conjoints de ces traitements de données, en tant qu'ils concernent les Apprenants, au sens de
        l'article 26 du RGPD. Ce statut reflète les responsabilités respectives de l'Utilisateur et de M@ths'n Co. vis-à-vis des Apprenants et les attentes légitimes de ces derniers, conformément aux
        lignes directrices des autorités compétentes.
      </p>

      <p className="mt-3">
        M@ths'n Co. se chargera également de recevoir et traiter, en collaboration avec l'Utilisateur, les demandes d'exercice de droits des Apprenants émises sur le fondement des articles 15 à 22 du
        RGPD.
      </p>

      <p className="mt-3">
        M@ths'n Co. s'engage à ne partager aucune donnée des Utilisateurs avec un tiers. Les données ne sont utilisées qu'à des fins de fonctionnement de la plateforme ou de communication sur ses
        fonctionnalités.
      </p>

      {/* Propriété intellectuelle ============================================ */}

      <h2 className="mt-3">7. Propriété intellectuelle</h2>

      <p className="mt-3">
        La marque M@ths en-vie et les logos sont protégés au titre de la législation internationale de la propriété intellectuelle. Ces éléments constituent, sauf indications contraires la propriété
        exclusive de M@ths'n Co. Toute utilisation, reproduction ou représentation, par quelque procédé que ce soit et sur quelques supports que ce soit, de tout ou partie de ces éléments, n'est pas
        autorisée sans le consentement express de M@ths'n Co.
      </p>

      <p className="mt-3">
        Le code des applications est sous licence libre, posté sur la Forge des Communs Numériques Educatifs, sous licence GNU General Public License v3.0 or later :{" "}
        <a href="https://www.gnu.org/licenses/gpl-3.0-standalone.html">en savoir plus</a>
      </p>

      <p className="mt-3">
        Tous les contenus relatifs aux contributions de l'Utilisateur sont sous licence <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/deed.fr">CC BY-NC-SA 4.0</a>
      </p>

      <p className="mt-3">
        L'Utilisateur qui assure des fonctions d'enseignement peut librement utiliser les problèmes, photos et photo-problèmes issus de la banque dans le cadre de sa classe et de son établissement et
        notamment les différentes éditions proposées par la plateforme (édition pour vidéoprojection pour impression au format PDF).
      </p>

      <p className="mt-3">
        Chaque Utilisateur s'engage à partager dans la banque collaborative des problèmes, photos ou photo-problèmes originaux ne provenant pas d'un autre auteur ou d'une publication existante.
      </p>

      <p className="mt-3">Tout manquement aux conditions d'utilisation peut entraîner la suppression de l'Utilisateur et de toutes ses données (problèmes, commentaires…) de la plateforme.</p>

      {/* Liens hypertextes =================================================== */}

      <h2 className="mt-3">8. Liens hypertextes</h2>

      <p className="mt-3">
        Le Site propose des liens hypertextes vers des sites web édités et/ou gérés par des tiers. Dans la mesure où aucun contrôle n'est exercé sur ces ressources externes, l'Utilisateur reconnaît
        que M@ths'n Co. n'assume aucune responsabilité relative à la mise à disposition de ces ressources et ne peut être tenue responsable quant à leur contenu.
      </p>

      {/* Responsabilité de M@ths'n Co ======================================== */}

      <h2 className="mt-3">9. Responsabilité de M@ths'n Co</h2>

      <p className="mt-3">
        M@ths'n Co. est responsable du maintien des Services en ligne et de l'accessibilité du serveur qui héberge son Site, sous réserve des interruptions ponctuelles pour maintenance.
      </p>

      <p className="mt-3">
        La responsabilité de Maths'n Co. ne saurait être recherchée pour des défaillances pour quelque cause que cela soit, comme dues à d'éventuelles saturations de réseau (Internet comme local) ou
        encore au cas où les matériels et les connexions de l'Utilisateur seraient défectueux, non compatibles ou de capacités techniques insuffisantes ou inadaptées à ce type d'exploitation. De même,
        il appartient à l'Utilisateur de s'assurer que les dispositifs de filtrage et/ou de protection qu'il aurait mis en place n'empêchent pas l'accès au Site, non plus que le fonctionnement de
        celui-ci.
      </p>

      <p className="mt-3">
        M@ths'n co. s'efforce d'assurer au mieux de ses possibilités l'exactitude et la mise à jour des informations figurant dans le Site dont elle se réserve le droit de corriger, à tout moment et
        sans préavis, le contenu. Toutefois, M@ths'n Co. ne peut garantir l'exactitude des informations mises à disposition sur le Site.
      </p>

      <p className="mt-3">En conséquence, M@ths'n Co. décline toute responsabilité&nbsp;:</p>
      <ul>
        <li>pour toute interruption du Site, survenance de bogues ou autre événement rendant le Site impropre à son usage&nbsp;;</li>
        <li>pour toute inexactitude ou omission portant sur les informations disponibles sur le Site&nbsp;;</li>
        <li>pour tous les contenus générés par l'intelligence artificielle (aide, phrase réponse, explications, solution du problème…)</li>
        <li>et, plus généralement, pour tout dommage direct ou indirect, quelle qu'en soit la cause ou la nature, résultant de la consultation et de l'utilisation du Site.</li>
      </ul>

      <p className="mt-3">La responsabilité de M@ths'n Co. ne pourra être engagée en cas de force majeure ou de faits indépendants de sa volonté.</p>

      {/* Disponibilité des Services ========================================== */}

      <h2 className="mt-3">10. Disponibilité des Services</h2>

      <p className="mt-3">
        Le format web des Services permet un fonctionnement sur la majorité des navigateurs web. L'Utilisateur doit disposer d'un appareil (ordinateur, tablette ou smartphone) équipé d'un navigateur
        web moderne et d'un accès au réseau Internet.
      </p>

      <p className="mt-3">M@ths'n Co. ne peut garantir la compatibilité de son Site avec tous les dispositifs actuels ou futurs.</p>

      {/* Modifications des CGU =============================================== */}

      <h2 className="mt-3">11. Modifications des CGU</h2>

      <p className="mt-3">
        Les présentes conditions générales d'utilisation pourront faire l'objet de modifications conformément à une éventuelle évolution des Services. Ces modifications peuvent avoir lieu en tout
        temps et sans préavis. Aussi les internautes sont-ils invités à consulter ces conditions d'utilisation régulièrement.
      </p>

      <p className="mt-3">
        En cas de modification des présentes conditions d'utilisation, les Utilisateurs disposant d'un Compte recevront une alerte sous la forme d'une notification sur l'application. La date de
        dernière modification des présentes conditions générales d'utilisation est indiquée en haut de cette page.
      </p>

      {/* Loi applicable ====================================================== */}

      <h2 className="mt-3">12. Loi applicable</h2>

      <p className="mt-3">Les présentes conditions générales sont soumises au droit français.</p>

      <p className="mt-3">
        En cas de litige né de l'utilisation des Services, nous vous invitons tout d'abord à vous rapprocher de M@ths'n Co. Pour que nous discutions ensemble du problème et tâchions de le résoudre
        ensemble de la meilleure manière possible.
      </p>

      <p className="mt-3">
        À défaut d'accord amiable, toute contestation relative à l'interprétation, la validité et/ou l'exécution des présentes relève de la compétence exclusive des tribunaux du tribunal judiciaire de
        Bonneville (France).
      </p>
    </div>
  );
}

export default Cgu;
