import { faArrowUpRightFromSquare, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDocumentTitle } from "../hooks";
import { Link } from "react-router-dom";
import Routes from "../routes";

function HelpRituel() {
  useDocumentTitle("Aide relative aux fonctionnalités du rituel de problèmes");

  return (
    <div className="my-0 mx-auto" style={{ maxWidth: 960 }}>
      <h1>Aide « rituel de problèmes »</h1>

      <p className="mt-3">
        Ce module permet de proposer à vos élèves un rituel de problèmes s'appuyant sur une programmation et une progression sur les 5 périodes de l'année et ce, du CP au CM2. Cet entrainement vient
        en complément du travail autour de{" "}
        <a href="https://www.mathsenvie.fr/la-methode" rel="noreferrer" target="_blank">
          la méthode M@ths en-vie <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" />
        </a>{" "}
        qui propose un enseignement explicite et structuré de la résolution de problèmes. Cependant, il peut également être utilisé seul.
      </p>

      <div className="d-block text-center mt-3">
        <img src="img/rituel.webp" alt="Illustration du concept du rituel" width="70%" />
      </div>

      <p className="mt-3">Avant de commencer :</p>
      <ul>
        <li>Paramétrer dans votre profil votre zone de vacances afin que les problèmes du jour correspondent à la programmation par période.</li>
        <li>Paramétrer dans votre profil les niveaux des élèves de votre classe permettant d'afficher 1, 2 ou 3 problèmes par jour selon le nombre de niveaux.</li>
      </ul>

      <p className="mt-3">Chaque jour, un problème par niveau est tiré au sort dans la banque de problèmes collaborative en fonction de la programmation.</p>

      <p className="mt-3">Vous pouvez :</p>
      <ul>
        <li>afficher ou non le niveau des problèmes ;</li>
        <li>affichez ou non la difficulté des problèmes (de 1 à 3 *, selon les niveaux choisis) ;</li>
        <li>afficher ou non le type de problème ;</li>
        <li>faire un autre tirage parmi le type enseigné si le problème du jour ne vous convient pas.</li>
      </ul>

      <p className="mt-3">
        Cet outil ne vit que des contributions de ses utilisateurs. S'il vous est utile, nous serions ravis que vous puissiez contribuer à son fonctionnement en proposant à votre tour 3 énoncés de
        problèmes. Cela vous permettra alors d'accéder à l'intégralité de la banque pour éditer et vidéoprojeter les problèmes de votre choix !
      </p>
      <div className="text-center mt-3">
        <Link className="btn btn-primary p-2 m-1" to={Routes.CONTRIBUTE}>
          <FontAwesomeIcon icon={faPen} size="4x" />
          <h3 className="mt-2 fw-semibold">Contribuer</h3>
          <p>Partagez vos problèmes avec la communauté.</p>
        </Link>
      </div>
    </div>
  );
}

export default HelpRituel;
